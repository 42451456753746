import React, { useContext, useState } from "react";
import {
  Typography,
  Box,
  Button,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import resultsIcon from "../assets/smudgeDots.svg";
import bgIntro from "../assets/bgintro.svg";
import smudgeDotYellowIcon from "../assets/smudgeDotYellow.svg";
import smudgeDotBlueIcon from "../assets/smudgeDotBlue.svg";
import smudgeDotRedIcon from "../assets/smudgeDotRed.svg";
import AnswersContext from "../context/AnswersContext";
import { ThemeContext } from "../App";
import RootContainer from "../components/RootContainer";
import { DownloadForOfflineRounded } from "@mui/icons-material";
import { GAEvent } from "..";

const Result = () => {
  
  const { answers, clearAnswers } = useContext(AnswersContext);
  const { isDarkMode } = useContext(ThemeContext);
  const [open, setOpen] = useState(false);

  const handleClearAnswers = () => {
    clearAnswers();
    setOpen(false);
  };

  const handleSaveAsPDF = () => {
    GAEvent("user_action", "save_pdf");
    
    window.print();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderSubSection = (title, questions) => (
    <Box marginBottom={4}>
      <Typography
        variant="h6"
        style={{
          fontWeight: "bold",
          marginBottom: "16px",
          color: isDarkMode ? "#FFFFFF" : "#000000",
        }}
      >
        {title}
      </Typography>
      {questions.map((question, index) => (
        <Box key={index} marginBottom={3}>
          <Typography
            variant="body1"
            style={{
              marginBottom: "8px",
              color: isDarkMode ? "#FFFFFF" : "#000000",
            }}
          >
            {question.text}
          </Typography>
          <Typography
            variant="body2"
            style={{
              padding: "8px",
              backgroundColor: isDarkMode ? "#555555" : "#f5f5f5",
              borderRadius: "10px",
              color: isDarkMode ? "#FFFFFF" : "#000000",
            }}
            sx={{
              "@media print": {
                backgroundColor: "transparent !important",
                boxShadow: "none !important",
              },
            }}
          >
            {answers[question.id] || "No answer provided"}
          </Typography>
        </Box>
      ))}
    </Box>
  );

  const renderSection = (subSections) => (
    <Paper
      elevation={3}
      style={{
        padding: "16px",
        borderRadius: "10px",
        backgroundColor: isDarkMode
          ? "rgba(66, 66, 66, 0.7)"
          : "rgba(255, 255, 255, 0.7)",
        marginBottom: "24px",
      }}
      sx={{
        "@media print": {
          backgroundColor: "transparent !important",
          boxShadow: "none !important",
        },
      }}
    >
      {subSections.map((subSection, index) => (
        <div key={index}>
          {renderSubSection(subSection.title, subSection.questions)}
        </div>
      ))}
    </Paper>
  );

  return (
    <RootContainer backgroundImage={bgIntro}>
      <div id="printableArea">
        <Typography
          variant="h4"
          style={{
            fontWeight: "bold",
            marginBottom: "24px",
            fontSize: "32px",
            lineHeight: "1.2",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={resultsIcon}
            alt="Results Icon"
            style={{ width: "40px", height: "40px", marginRight: "8px" }}
          />
          Results
        </Typography>
        <Typography
          variant="body1"
          style={{ marginBottom: "24px", fontSize: "18px", lineHeight: "1.6" }}
        >
          Thank you for using the Software Evaluation Tool. We hope you got some
          useful insights. Here are the notes you made along the way.
        </Typography>
        <Box display="flex" justifyContent="space-between" marginBottom="24px">
          <Button
            variant="outlined"
            style={{
              borderRadius: "20px",
              color: isDarkMode ? "#FFFFFF" : "#000000",
              borderColor: isDarkMode ? "#FFFFFF" : "#000000",
              textTransform: "capitalize",
            }}
            sx={{
              "@media print": {
                display: "none",
              },
            }}
            onClick={handleClickOpen}
          >
            Clear My Answers
          </Button>
          <Button
            variant="contained"
            style={{
              borderRadius: "20px",
              textTransform: "capitalize",
              backgroundColor: "#4747AA",
              fontWeight: 600,
            }}
            sx={{
              "@media print": {
                display: "none",
              },
            }}
            startIcon={<DownloadForOfflineRounded />}
            onClick={handleSaveAsPDF}
          >
            Save as PDF
          </Button>
        </Box>

        <Box display="flex" alignItems="center" marginBottom="24px">
          <img
            src={smudgeDotYellowIcon}
            alt="Smudge Dot Yellow Icon"
            style={{ width: "24px", height: "24px", marginRight: "8px" }}
          />
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            Business Considerations
          </Typography>
        </Box>
        {renderSection([
          {
            title: "Purpose and Values",
            questions: [
              {
                id: "ProjectEvaluation1",
                text: "Are your own purpose and values sufficiently clear that you're able to apply them as criteria when evaluating a new project?",
              },
              {
                id: "ProjectEvaluation2",
                text: "When considering a new project, do you assess the purpose and values of your customer(s) or stakeholder(s) before making a decision?",
              },
              {
                id: "ProjectEvaluation3",
                text: "Under what circumstances would you decline a project (e.g. on ethical grounds) even if it could be financially lucrative?",
              },
            ],
          },
          {
            title: "Strategy",
            questions: [
              {
                id: "ProjectEvaluation4",
                text: "In your view, does the project have genuine strategic importance? Does it solve a problem that is worth solving?",
              },
              {
                id: "ProjectEvaluation5",
                text: "Is the project part of a broader business or go-to-market strategy? If so, how well do you understand the wider strategy and how does this project fit within it?",
              },
              {
                id: "ProjectEvaluation6",
                text: "Is the project a good fit with your own strategic goals? Is this the type of work you want to be doing?",
              },
            ],
          },
          {
            title: "Financial Viability",
            questions: [
              {
                id: "ProjectEvaluation7",
                text: "Are you and your customer(s) or stakeholder(s) fully cognisant of the estimated project costs? Can all parties afford to embark on the project?",
              },
              {
                id: "ProjectEvaluation8",
                text: "Who is paying for the project, and when? Has a fee structure been agreed? Do you need a contract?",
              },
              {
                id: "ProjectEvaluation9",
                text: "Do you or your customer(s) intend to monetize the product or solution? If so, what is the business model? Is the ongoing success of the project dependent on generating revenue?",
              },
            ],
          },
          {
            title: "Knowledge and Expertise",
            questions: [
              {
                id: "BusinessCapability1",
                text: "Do you / your team have existing domain knowledge or industry experience in this area?",
              },
              {
                id: "BusinessCapability2",
                text: "What insights do you have about the users you are building for? How well do you understand their pain points? What are the gaps?",
              },
              {
                id: "BusinessCapability3",
                text: "How well do you know your customer(s) or stakeholder(s)? Is there a good level of trust on both sides? If not, how will you build it?",
              },
            ],
          },
          {
            title: "People and Skills",
            questions: [
              {
                id: "BusinessCapability4",
                text: "Do you have sufficient people (designers, developers, etc) to tackle the project? What are the gaps?",
              },
              {
                id: "BusinessCapability5",
                text: "Do you have the relevant skills to tackle the project? Do you have the right blend of specialists and generalists? What are the gaps?",
              },
              {
                id: "BusinessCapability6",
                text: "What will you / your team learn from the project? How does it contribute to your professional development goals?",
              },
            ],
          },
          {
            title: "Planning",
            questions: [
              {
                id: "BusinessCapability7",
                text: "How do you plan a project? Do you plan collaboratively with your customer(s) or stakeholder(s)?",
              },
              {
                id: "BusinessCapability8",
                text: "Do you have sufficient time to tackle the project? What is driving the timeline and can you influence it?",
              },
              {
                id: "BusinessCapability9",
                text: "What other resources do you need to complete the project to a high standard (e.g. office space, equipment, etc)?",
              },
            ],
          },
          {
            title: "Methodology",
            questions: [
              {
                id: "BusinessProcesses1",
                text: "What overarching design and/or development methodology do you use when undertaking a project? Do you need one?",
              },
              {
                id: "BusinessProcesses2",
                text: "Is your methodology appropriate for the type and size of your team, project or customer?",
              },
              {
                id: "BusinessProcesses3",
                text: "Is the project team set up for success? Are roles and responsibilities sufficiently clear?",
              },
            ],
          },
          {
            title: "Project Management",
            questions: [
              {
                id: "BusinessProcesses4",
                text: "What tools and systems do you use to forecast and track progress, time and costs? What is the process for communicating changes?",
              },
              {
                id: "BusinessProcesses5",
                text: "What tools, meetings and forums do you use to communicate? Do the tools have sufficient capability and flexibility? Do the meetings have a clear purpose and cadence?",
              },
              {
                id: "BusinessProcesses6",
                text: "How do you capture learnings and hindsights during / after a project? What is the process for flowing those insights back into your process?",
              },
            ],
          },
          {
            title: "Review and Approval",
            questions: [
              {
                id: "BusinessProcesses7",
                text: "Who is involved in the quality, review and approval process? What is the workflow? Are roles and responsibilities sufficiently clear?",
              },
              {
                id: "BusinessProcesses8",
                text: "Is the review process working? Are the best ideas rising to the top? How could this be improved?",
              },
              {
                id: "BusinessProcesses9",
                text: "Who makes the final decision on if/when a product or feature ships? Is the approval process sufficiently clear?",
              },
            ],
          },
        ])}

        <Box display="flex" alignItems="center" marginBottom="24px">
          <img
            src={smudgeDotBlueIcon}
            alt="Smudge Dot Blue Icon"
            style={{ width: "24px", height: "24px", marginRight: "8px" }}
          />
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            Technical Considerations
          </Typography>
        </Box>
        {renderSection([
          {
            title: "Platform",
            questions: [
              {
                id: "TechnicalCapability1",
                text: "Hardware: Given your budget, resources and priorities, which device(s) will you support and which can you choose to omit? Total cost of ownership is an important consideration.",
              },
              {
                id: "TechnicalCapability2",
                text: "Toolset: How will your platform choice inform your development toolset? What are the best or most appropriate tools for your chosen platform(s)?",
              },
              {
                id: "TechnicalCapability3",
                text: "Architecture: What constraints will your chosen platform(s) place on your architecture decisions?",
              },
            ],
          },
          {
            title: "Ecosystem",
            questions: [
              {
                id: "TechnicalCapability4",
                text: "Hardware: Some devices have a broader range of applications and accessories than others.",
              },
              {
                id: "TechnicalCapability5",
                text: "Toolset: The larger and more mature the ecosystem, the easier it is to source developer tools, attract and onboard talent, and get peer support with common problems.",
              },
              {
                id: "TechnicalCapability6",
                text: "Architecture: Does your architecture take full advantage of the hardware, tools and software libraries available in the ecosystem?",
              },
            ],
          },
          {
            title: "Security",
            questions: [
              {
                id: "TechnicalCapability7",
                text: "Hardware: By design, some devices are more secure or securable than others.",
              },
              {
                id: "TechnicalCapability8",
                text: "Toolset: Some toolsets are secured by their providers, others are reliant on communities of individual contributors.",
              },
              {
                id: "TechnicalCapability9",
                text: "Architecture: Designing an appropriate security profile with separation between components can help minimise risk.",
              },
            ],
          },
          {
            title: "Life Expectancy",
            questions: [
              {
                id: "TechnicalSustainability1",
                text: "Hardware: Devices come and go, as do hardware manufacturers. There are potential risks with adopting a device or platform too early or too late in its lifecycle.",
              },
              {
                id: "TechnicalSustainability2",
                text: "Toolset: Your toolset needs to live as long as the solution is operational. As software platforms gain new capabilities, some toolsets will support them more quickly than others.",
              },
              {
                id: "TechnicalSustainability3",
                text: "Architecture: Is your architecture robust enough to adapt as your chosen platform evolves?",
              },
            ],
          },
          {
            title: "Support",
            questions: [
              {
                id: "TechnicalSustainability4",
                text: "Hardware: Some devices have more service and support options than others, making them easier to update, repair or replace.",
              },
              {
                id: "TechnicalSustainability5",
                text: "Toolset: A good development toolset is one where every element is dependable and maintainable over the long term. Some toolsets are supported by the platform vendor, others rely on smaller companies or individual contributors.",
              },
              {
                id: "TechnicalSustainability6",
                text: "Architecture: A simple architecture of well-defined components is typically easier to support. Some components may not be supported over the lifetime of a project.",
              },
            ],
          },
          {
            title: "Portability",
            questions: [
              {
                id: "TechnicalSustainability7",
                text: "Hardware: As the business and technology landscape evolves, it's important to consider if/how your solution will support future devices.",
              },
              {
                id: "TechnicalSustainability8",
                text: "Toolset: Consider the trade-offs between using tools that offer short-term functionality vs long-term portability.",
              },
              {
                id: "TechnicalSustainability9",
                text: "Architecture: Is your architecture flexible enough to support emerging platforms? Building solutions using interoperable standards can help ensure compatibility with future technology.",
              },
            ],
          },
        ])}

        <Box display="flex" alignItems="center" marginBottom="24px">
          <img
            src={smudgeDotRedIcon}
            alt="Smudge Dot Red Icon"
            style={{ width: "24px", height: "24px", marginRight: "8px" }}
          />
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            User Experience
          </Typography>
        </Box>
        {renderSection([
          {
            title: "Platform",
            questions: [
              {
                id: "UXConsistency1",
                text: "Do the appearance and behaviour of UI elements reflect the conventions of the platform?",
              },
              {
                id: "UXConsistency2",
                text: "Consider how frequently users will access the experience: the less frequent the usage the more you should leverage platform conventions.",
              },
              {
                id: "UXConsistency3",
                text: "Does your interface match user expectations of speed and fluidity (e.g. loading data, animations)?",
              },
            ],
          },
          {
            title: "Solution",
            questions: [
              {
                id: "UXConsistency4",
                text: "Are UI elements (e.g. fonts, colours etc) in harmony with each other and consistent at every touch point? Are you following a style guide? If so, be mindful of balancing your brand requirements with the user's expectations of the platform.",
              },
              {
                id: "UXConsistency5",
                text: "Are UI and navigation elements used for a clear and consistent purpose throughout the experience?",
              },
            ],
          },
          {
            title: "Mental Models",
            questions: [
              {
                id: "UXConsistency6",
                text: "Does your interface leverage what users believe they already know about how it should behave? (e.g. pushing down a pedal in a car implies “more”, pinching fingers on mobile implies “zoom”).",
              },
              {
                id: "UXConsistency7",
                text: "For every interaction, consider the trade-off between leveraging an existing metaphor and introducing a new one that users will need to learn.",
              },
            ],
          },
          {
            title: "Interactivity",
            questions: [
              {
                id: "UXCommunication1",
                text: "Does every interactive element offer immediate feedback to the user to acknowledge an action (e.g. do buttons respond instantly when touched)?",
              },
              {
                id: "UXCommunication2",
                text: "Be mindful of unnecessarily communicating to the user about low-level system status.",
              },
            ],
          },
          {
            title: "Relevance",
            questions: [
              {
                id: "UXCommunication3",
                text: "Are you communicating only the most relevant intermittently-important information at all times (e.g. operational status, Wi-Fi signal, battery life)?",
              },
              {
                id: "UXCommunication4",
                text: "Is persistently-available information readily accessible but sufficiently unobtrusive?",
              },
            ],
          },
          {
            title: "Proactivity",
            questions: [
              {
                id: "UXCommunication5",
                text: "Intermittently-important information becomes critical;",
              },
              {
                id: "UXCommunication6",
                text: "A one-off event occurs that might need the user's attention or they've elected to be notified about.",
              },
              {
                id: "UXCommunication7",
                text: "Is every piece of user dialogue in your interface useful and/or necessary?",
              },
              {
                id: "UXCommunication8",
                text: "Do you use language that is understandable by everyone in your audience?",
              },
              {
                id: "UXCommunication9",
                text: "Are you matching the user's expectations about what communications they have opted to receive?",
              },
            ],
          },
          {
            title: "Accessibility",
            questions: [
              {
                id: "UXFlexibility1",
                text: "Do you support accessibility features (e.g. variable font size, speech-to-text) where appropriate?",
              },
              {
                id: "UXFlexibility2",
                text: "Do you provide multiple input methods (e.g. shortcuts, gestures, voice recognition)?",
              },
              {
                id: "UXFlexibility3",
                text: "Have you considered a variety of constraints (e.g. colour-blindness, one-handed use, bright sunlight)?",
              },
            ],
          },
          {
            title: "Context",
            questions: [
              {
                id: "UXFlexibility4",
                text: "Does your user flow support a variety of contextual entry points? Different users will be solving for different needs (e.g. “I want something to eat right now” vs “What is the best restaurant in the city?”)",
              },
              {
                id: "UXFlexibility5",
                text: "At any point in the flow, is the user forced to stop what they're doing or go backwards in order to continue or complete a task?",
              },
            ],
          },
          {
            title: "Fallibility",
            questions: [
              {
                id: "UXFlexibility6",
                text: "How forgiving is your interface? Could a user accidentally perform a destructive action?",
              },
              {
                id: "UXFlexibility7",
                text: "When a user makes an error, do you provide clear options to keep moving? (e.g. “Sorry, that hotel is full, but here are three similar options nearby”).",
              },
            ],
          },
        ])}
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: isDarkMode ? "#424242" : "#FFFFFF",
            color: isDarkMode ? "#FFFFFF" : "#000000",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Clear Answers"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            color={isDarkMode ? "#FFFFFF" : "#000000"}
            id="alert-dialog-description"
          >
            Are you sure you want to clear all answers? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClearAnswers} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </RootContainer>
  );
};

export default Result;
