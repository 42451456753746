import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useLocation } from 'react-router-dom';
import App from './App';
import './index.css';
import ReactGA from "react-ga4";

ReactGA.initialize("G-PCSQ86TYC5");

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.event({
      category: "route_change",
      action: location.pathname,
    });

    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: location.pathname });
  }, [location]);

  return null;
};

export const GAEvent = (category, action) => {
  ReactGA.event({
    category,
    action
  });
};

const Main = () => (
  <BrowserRouter>
    <RouteChangeTracker />
    <App />
  </BrowserRouter>
);

ReactDOM.render(<Main />, document.getElementById('root'));